/* Custom Container */

.customContainer-home {
  padding-top: 80px;
}

/* Custom Row */

.customRow-home > * {
  display: flex !important;
  justify-content: center !important;
}

#col-text-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Title Home */

.title-home {
  font-size: 35px;
  font-weight: 600;
  color: #000;
  margin-bottom: 40px;
  text-align: center;
}

.second-titleHome {
  font-size: 35px;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
}

/* Content Texts */

.content-texts {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}

.paragraph {
  margin: 0 auto;

  font-size: 1.2rem;

  max-width: 80%;

  margin-top: 20px;

  font-weight: 500;
}

/* Galery Photos */

.space-title {
  text-align: center;
  padding-top: 40px;
  font-size: 1.5rem;
}

.content-gallery-top,
.content-gallery-middle,
.content-gallery-bot,
.content-gallery-bot2 {
  display: flex;

  width: 100%;

  justify-content: center;

  flex-wrap: wrap;
}

.content-gallery-bot2 {
  margin-bottom: 30px;
}

.photo1 {
  background-image: url(../../img/casaNova1.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px;

  height: 200px;

  margin-top: 10px;

  margin: 10px 10px;
}

.photo2 {
  background-image: url(../../img/casaNova2.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px;

  height: 200px;

  margin: 10px 10px;
}

.photo3 {
  background-image: url(../../img/casaNova3.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px;

  height: 200px;

  margin: 10px 10px;
}

.photo4 {
  background-image: url(../../img/casaNova4.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px;

  height: 200px;

  margin: 10px 10px;
}

.photo5 {
  background-image: url(../../img/casaNova5.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px !important;

  height: 300px;

  margin: 10px 10px;
}

.photo6 {
  background-image: url(../../img/casaNova6.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px !important;

  height: 300px;

  margin: 10px 10px;
}

.photo7 {
  background-image: url(../../img/casaNova7.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px !important;

  height: 300px;

  margin: 10px 10px;
}

.photo8 {
  background-image: url(../../img/casaNova8.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 200px !important;

  height: 300px;

  margin: 10px 10px;
}

.photo9 {
  background-image: url(../../img/casaNova9.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 430px !important;

  height: 300px;

  margin: 10px 10px;
}

.photo10 {
  background-image: url(../../img/casaNova10.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 430px !important;

  height: 300px;

  margin: 10px 10px;
}

.photo11 {
  background-image: url(../../img/casaNova11.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 430px !important;

  height: 300px;

  margin: 10px 10px;
}

.photo12 {
  background-image: url(../../img/casaNova12.jpg);

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  border-radius: 10px;

  width: 430px !important;

  height: 300px;

  margin: 10px 10px;
}

/* Mobile */

@media (max-width: 439px) {
  .photo1,
  .photo2,
  .photo3,
  .photo4,
  .photo5,
  .photo6,
  .photo7,
  .photo8 {
    max-width: 140px;
  }
}

@media screen and (min-width: 659px) and (max-width: 879px) {
  .photo1,
  .photo2,
  .photo3,
  .photo4,
  .photo5,
  .photo6,
  .photo7,
  .photo8 {
    max-width: 140px;
  }
  .photo9,
  .photo10,
  .photo11,
  .photo12 {
    max-width: 600px !important;
  }
}
