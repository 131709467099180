.title-importancia {
  margin: 50px 0;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.subtitle-importancia {
  margin: 20px 0;
  text-align: center;
}

.paragraph-importancia {
  color: #000;
  font-weight: 400;
  text-align: center;
  max-width: 800px;
}

.paragraph-specialAbout {
  font-weight: 500;
  text-align: center;
}

.rowImportancia {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
