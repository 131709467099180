.title-aboutUs {
  text-align: center;
  margin: 50px 0;
  font-size: 2.5rem;
  font-weight: 600;
}

.paragraph-aboutUs {
  font-size: 18px;
  max-width: 500px;
}

.row-aboutUs {
  margin: 50px 0;
  justify-content: center !important;
}

.title-localization {
  text-align: center;
  margin-bottom: 20px;
}

.colImgAbout {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.colParagraphsAboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
