.imagesOrientations {
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 575px) {
  .imagesOrientations {
    width: 100% !important;
    margin: 0 !important;
  }
}
