.container-navbar {
  background-color: #261c2b;
}

.config-navbar {
  margin: 0 auto;
}

.logo-navbar {
  width: 80px;
}

.navbar-toggler-icon {
  background-image: url(../../img/hamb-item.png) !important;
  height: 35px;
  width: 35px;
}

.navbar-toggler {
  border: 0.2px solid #fff !important;
}

.link-orientacao {
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  padding-left: 30px;
}

.link-orientacao:hover {
  color: #fff;
}

.links-navbar {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  margin: 0 15px;
}

.links-navbar:hover {
  color: #fff;
}

.links-navbar-disabled,
.links-navbar-dropdown {
  text-decoration: none;
  font-size: 13px;
  margin: 15px 0;
  max-width: 200px !important;
  color: #fff;
}

.links-navbar-disabled {
  color: #7a7a7a;
  pointer-events: none;
}

.links-navbar-dropdown:hover,
.links-navbar-disabled:hover {
  color: #fff !important;
}

.dropdown-item:hover {
  background-color: #4e375a !important;
}

#basic-nav-dropdown {
  color: #fff;
}

.dropdown-menu {
  background-color: #4e375a !important;
}

#dropdown-curses {
  color: #fff;
  margin: 0 15px;
}

@media (max-width: 952px) {
  .dropdown-title {
    margin: 0 16px;
  }
}
