@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300&display=swap');

.containerLinktree {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  padding: 30px 0;
  min-height: 100vh;
  width: 100%;
  background-image: url('../../imgLinktree/background-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.allContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.colTest {
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .colTest {
    flex-wrap: wrap;
    justify-content: center;
  }
  .cardCurses {
    margin: 20px 0 !important;
  }
}

.col1Linktree {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleLinktree {
  font-weight: 300;
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: 2px;
  font-weight: bold;
}

.subTitleLinktree {
  font-weight: 300;
  font-size: 1.8rem;
  text-align: center;
  max-width: 700px;
  margin-top: 70px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

@media (max-width: 575px) {
  .subTitleLinktree {
    font-size: 1.4rem;
  }
}

.btnGloria {
  font-family: 'Poppins';
  padding: 8px 40px;
  width: 250px;
  background-color: #9aff9a;
  border-radius: 15px;
  border: none;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  font-weight: 500;
}

.btnGloria:hover {
  color: #000;
}

.btnGloria a {
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.btnGloria {
  margin-bottom: 7px;
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btnLinktree {
  font-family: 'Poppins';
  padding: 6px 40px;
  margin: 10px 10px;
  width: 250px;
  background-color: #ffffff;
  border-radius: 15px;
  border: none;
  color: #000;
  text-decoration: none;
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.btnLinktree:hover {
  color: #000;
}

/* COL 2 */

.titleAll {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
  font-size: 1.8rem;
  font-weight: bold;
}

.col2Linktree {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardCurses {
  color: #ffffff;
  border: 0;
  text-align: center;
  background-color: #1d1d1d;
  margin: 0 20px;
}

.customTitle {
  margin-bottom: 30px;
}

.customText {
  text-align: center;
  margin-bottom: 30px;
}

.wrapContents {
  display: flex;
  flex-direction: column;
}

.btnInfo {
  border-radius: 10px;
  padding: 8px 15px;
  text-decoration: none;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: 'Poppins';
  background-color: rgb(31, 128, 255);
}

.btnInfo:hover {
  color: #fff;
}

.btnInfoWpp {
  border-radius: 10px;
  padding: 8px 15px;
  text-decoration: none;
  color: #000000;
  border: none;
  cursor: pointer;
  font-family: 'Poppins';
  background-color: #9aff9a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnInfoWpp:hover {
  color: #000000;
}
